import useCases from '../../data/useCases'
import UseCaseList from './UseCaseList'

export default function UseCasesPage() {
  return (
    <div className="use-case-page">
      <UseCaseList useCases={useCases} />
    </div>
  )
}
