const reportsData = [
  {
    id: 1,
    title: 'TOPLINE',
    embed: {
      vizId: 'viz1709820744155',
      img: 'https://public.tableau.com/static/images/TO/TOPLINEREPORT/FINANCE/1.png',
      name: 'TOPLINEREPORT/FINANCE',
    },
  },
  {
    id: 2,
    title: 'Online Marketing Topline',
    embed: {
      vizId: 'viz1710515846221',
      img: 'https://public.tableau.com/static/images/On/OnlineMarketingTopline/SPENDDASHBOARDRD/1_rss.png',
      name: 'OnlineMarketingTopline/SPENDDASHBOARDRD',
    },
  },
  {
    id: 3,
    title: 'UNDERSTOCK / OVERSTOCK',
    embed: {
      vizId: 'viz1709821133674',
      img: 'https://public.tableau.com/static/images/Un/UnderstockOverstockInventoryAnalysis/INVENTORYANALYSIS/1.png',
      name: 'UnderstockOverstockInventoryAnalysis/INVENTORYANALYSIS',
    },
  },
  {
    id: 4,
    title: 'Retail stock availability (nákupní)',
    embed: {
      vizId: 'viz1709820810405',
      img: 'https://public.tableau.com/static/images/Re/Retailstockavailability/Version1ENG/1.png',
      name: 'Retailstockavailability/Version1ENG',
    },
  },
  {
    id: 5,
    title: 'Profit & Loss Statement',
    embed: {
      vizId: 'viz1709820984223',
      img: 'https://public.tableau.com/static/images/Pr/ProfitLossStatementofanSaaScompany/PL/1.png',
      name: 'ProfitLossStatementofanSaaScompany/PL',
    },
  },
  {
    id: 6,
    title: 'RETAIL STORE PERFORMANCE REPORT',
    embed: {
      vizId: 'viz1709821882825',
      img: 'https://public.tableau.com/static/images/RE/RETAILSTOREPERFORMANCEREPORT/PRODEJNA/1.png',
      name: 'RETAILSTOREPERFORMANCEREPORT/PRODEJNA',
    },
  },
  {
    id: 7,
    title: 'RETAIL STORE TRAFFIC & CONVERSION',
    embed: {
      vizId: 'viz1709821035132',
      img: 'https://public.tableau.com/static/images/RE/RETAILSTORETRAFFICANDCONVERSION/ALLSTORES/1.png',
      name: 'RETAILSTORETRAFFICANDCONVERSION/ALLSTORES',
    },
  },
  {
    id: 8,
    title: 'PRODUCTS CATEGORY ROLE IN PET STORE',
    embed: {
      vizId: 'viz1709822085810',
      img: 'https://public.tableau.com/static/images/PR/PRODUCTSCATEGORYROLEINPETSTORE/Introduction/1.png',
      name: 'PRODUCTSCATEGORYROLEINPETSTORE/Introduction',
    },
  },
  {
    id: 9,
    title: 'MARKET PERFORMANCE',
    embed: {
      vizId: 'viz1709821930397',
      img: 'https://public.tableau.com/static/images/Ma/MarketPerformance_16191074568360/NielsenSalesElectro/1.png',
      name: 'MarketPerformance_16191074568360/NielsenSalesElectro',
    },
  },
  {
    id: 10,
    title: 'Picking Dashboard Analytický',
    embed: {
      vizId: 'viz1709822024810',
      img: 'https://public.tableau.com/static/images/Pi/PickingDashboard-analytick/Dash/1.png',
      name: 'PickingDashboard-analytick/Dash',
    },
  },
  {
    id: 11,
    title: 'Budget Controlling',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
  },
  {
    id: 12,
    title: 'B2B RFM analýza',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
  },
  {
    id: 13,
    title: 'B2B Retail Sales',
    embed: {
      vizId: 'viz1710881437168',
      img: 'https://public.tableau.com/static/images/Bi/BioGroceriesSalesAppVersion/Home/1.png',
      name: 'BioGroceriesSalesAppVersion/Home',
    },
  },
  {
    id: 14,
    title: 'Email Marketing Campaigns',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
  },
  {
    id: 15,
    title: 'Customer Insights with Data Cloud',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
  },
  {
    id: 16,
    title: 'Customer Cohort Analysis', // !!
    embed: {
      vizId: 'viz1710881648764',
      img: 'https://public.tableau.com/static/images/Cu/CustomerCohortReportAppVersion/CustomerCohortReport/1.png',
      name: 'CustomerCohortReportAppVersion/CustomerCohortReport',
    },
  },
  {
    id: 17,
    title: 'Sales, Customer, and Product Performance', // !!
    embed: {
      vizId: 'viz1710881737066',
      img: 'https://public.tableau.com/static/images/Sa/SalesToplineAppVersion/Topline/1.png',
      name: 'SalesToplineAppVersion/Topline',
    },
  },
  {
    id: 18,
    title: 'Škoda Geo Risks', // !!
    embed: {
      vizId: 'viz1710881818087',
      img: 'https://public.tableau.com/static/images/Sk/SkodaGeoRisksAppVersion/GeopoliticalRiskLevel/1.png',
      name: 'SkodaGeoRisksAppVersion/GeopoliticalRiskLevel',
    },
  },
  {
    id: 19,
    title: 'Náklady na employees - FL',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
  },
  {
    id: 20,
    title: 'Customer Experience - FL',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
  },
  {
    id: 21,
    title: 'Kanibalizace',
    embed: {
      vizId: '',
      img: '',
      name: '',
    },
  },
]

export default reportsData
