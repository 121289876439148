import React, { useEffect, useRef } from 'react'

function TableauEmbed({ vizId, img, name }) {
  const vizElement = useRef(null)

  useEffect(() => {
    const scriptElement = document.createElement('script')
    scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js'
    const currentVizElement = vizElement.current

    currentVizElement.parentNode.insertBefore(scriptElement, currentVizElement)

    // Cleanup function to remove script when component unmounts
    return () => {
      if (currentVizElement && currentVizElement.parentNode) {
        currentVizElement.parentNode.removeChild(scriptElement)
      }
    }
  }, [])

  return (
    <div
      className="tableauPlaceholder"
      id={vizId}
      style={{ position: 'relative' }}
    >
      <object
        ref={vizElement}
        className="tableauViz"
        style={{ display: 'none' }}
      >
        <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
        <param name="embed_code_version" value="3" />
        <param name="site_root" value="" />
        <param name="name" value={name} />
        <param name="tabs" value="no" />
        <param name="toolbar" value="yes" />
        <param name="static_image" value={img} />
        <param name="animate_transition" value="yes" />
        <param name="display_static_image" value="yes" />
        <param name="display_spinner" value="yes" />
        <param name="display_overlay" value="yes" />
        <param name="display_count" value="yes" />
        <param name="language" value="en-US" />
      </object>
    </div>
  )
}

export default TableauEmbed
