import { useGlobalState } from '../../hooks/useGlobalState'

export default function ReportItem({ report }) {
  const { setActiveReport } = useGlobalState()
  return (
    <button
      type="button"
      className="report-card"
      onClick={() => setActiveReport(report)}
    >
      <h2 className="report-card-title">{report.title}</h2>
      <div className="report-card-image">
        <img
          src={'/reports/' + report.id + '.png'}
          alt={report.title}
          width={736}
          height={454}
        />
      </div>
    </button>
  )
}
