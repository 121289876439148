import TableauEmbed from './TableauEmbed'

export default function ReportDetail({ report }) {
  return (
    <div className="report-detail">
      <h1 className="report-detail-title">{report.title}</h1>
      <div className="report-detail-code">
        <TableauEmbed
          vizId={report.embed.vizId}
          img={report.embed.img}
          name={report.embed.name}
        />
      </div>
    </div>
  )
}
